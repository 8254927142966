/* .wrapper {
  margin-top: 100px;
} */
.preloader {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #f4f6f9;
  height: 100vh;
  width: 100%;
  transition: height 200ms linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

/* h2 {
  font-weight: 600 !important;
}

table.negrita thead > tr {
  background-color: #aaaaaa;
}
table.negrita th {
  padding: 5px !important;
  font-weight: 900 !important;
}

table.negrita td {
  padding: 4px !important;
  font-weight: 700 !important;
} */

.agrega-borde {
  border: 1px solid #dddddd !important;
}

.home-background  {
  background-image: url("/public/images/background.png") !important;
  background-color: #de1f30 !important;
  width: 1500px !important;
  height: 1500px !important;
}

.no-padding {
  padding: 0;
}

.no-margin  {
  margin: 0;
}

.force-border {
  border: 1px solid #dddddd !important;
  border-radius: 4px px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.main-container {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.brand-logo {
  height: 70px;
}

.navbar-height {
  height: 70px;
  padding: 0 0 0 15px !important;
}

.login-title {
  color: white !important;
}

.card-col {
  max-width: 350px;
}

.btn-red {
  color: white !important;
  background-color: red !important;
  border-color: red !important;
}

.btn-whatsapp {
  color: white !important;
  background-color: #1fc930 !important;
  border-color: #1fc930 !important;
}

.btn-forgot {
  color: #cccccc !important;
  background-color: transparent !important;
  border: transparent !important;
}

.btn-forgot:hover {
  color: #ffffff !important;
  transition: 0.7s;
}

.section-title {
  padding-top: 5px;
}

.bg-dark-blue {
  background-color: #20346b !important;
}

.btn-toolbar-blue {
  background-color: #1a459d !important;
  border-color: #1a459d !important;
}

.btn-toolbar-blue:hover {
  background-color: #20346b !important;
  border-color: #20346b !important;
}

.btn-toolbar-red {
  background-color: #de1f30 !important;
  border-color: #de1f30 !important;
}

.btn-toolbar-red:hover {
  background-color: #a51e23 !important;
  border-color: #a51e23 !important;
}

.black {
  color: black;
}

.black:hover {
  color: black;
}

.blue {
  background-color: #1a459d !important;
  border-color: #1a459d !important;
}

.clickable {
  cursor: pointer;
}
