/* .rbc-time-view .rbc-row {
  min-height: 0px;
}

.rbc-time-view .rbc-row div:nth-child(1) {
  display: none;
  height: 0px !important;
} */

.rbc-allday-cell {
  display: none;
}
